@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Custom styling for datepicker  */
span.disabled {
  color: #D1D5DB;
  cursor: not-allowed;
}

span.selected {
  color: #fff;
  background-color: #1A56DB;
}

div.datepicker-controls {
  display: flex;
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
}

button.today-btn {
  /* Base styles */
  color: #1a202c;
  /* Equivalent to text-gray-900 in Tailwind CSS */
  background-color: #ffffff;
  /* Equivalent to bg-white in Tailwind CSS */
  border-width: 1px;
  /* Equivalent to border in Tailwind CSS */
  border-style: solid;
  /* Equivalent to border in Tailwind CSS */
  border-color: #cbd5e0;
  /* Equivalent to border-gray-300 in Tailwind CSS */
  outline: none;
  /* Equivalent to focus:outline-none in Tailwind CSS */
  border-radius: 0.5rem;
  /* Equivalent to rounded-lg in Tailwind CSS */
  font-weight: 500;
  /* Equivalent to font-medium in Tailwind CSS */
  font-size: 0.875rem;
  /* Equivalent to text-sm in Tailwind CSS */
  padding-left: 1.25rem;
  /* Equivalent to px-5 in Tailwind CSS */
  padding-right: 1.25rem;
  /* Equivalent to px-5 in Tailwind CSS */
  padding-top: 0.625rem;
  /* Equivalent to py-2.5 in Tailwind CSS */
  padding-bottom: 0.625rem;
  /* Equivalent to py-2.5 in Tailwind CSS */
  margin-bottom: 0.5rem;
  /* Equivalent to mb-2 in Tailwind CSS */

  /* Hover state */
  transition: background-color 0.3s, border-color 0.3s;
}

button.today-btn:hover {
  background-color: #f7fafc;
  /* Equivalent to hover:bg-gray-100 in Tailwind CSS */
}

/* Focus state */
button.today-btn:focus {
  box-shadow: 0 0 0 2px rgba(163, 169, 174, 0.5);
  /* Equivalent to focus:ring-4 in Tailwind CSS */
}

button.today-btn:focus {
  box-shadow: 0 0 0 2px rgba(209, 213, 219, 0.5);
  /* Equivalent to focus:ring-gray-100 in Tailwind CSS */
}

/* Dark mode styles */
.dark button.today-btn {
  background-color: #2d3748;
  /* Equivalent to dark:bg-gray-800 in Tailwind CSS */
  color: #ffffff;
  /* Equivalent to dark:text-white in Tailwind CSS */
  border-color: #718096;
  /* Equivalent to dark:border-gray-600 in Tailwind CSS */
}

.dark button.today-btn:hover {
  background-color: #4a5568;
  /* Equivalent to dark:hover:bg-gray-700 in Tailwind CSS */
  border-color: #718096;
  /* Equivalent to dark:hover:border-gray-600 in Tailwind CSS */
}

.dark button.today-btn:focus {
  box-shadow: 0 0 0 2px rgba(100, 112, 128, 0.5);
  /* Equivalent to dark:focus:ring-gray-700 in Tailwind CSS */
}